import './UserView.css'

export default function UserView({rels, user}) {

  return (<>
    {user && (`
      ${user.FirstName}
      ${user.LastName}
      (${user.Username})
      ${user.Email}
    `)}
  </>)
}
