
const checkUser = async (username) => {
  return await fetch(`https://qooxayb23i.execute-api.us-east-1.amazonaws.com/getUser/${username}`)
    .then(async resp => {
      const res = await resp.json()
      // console.log('res',res)
      if (res.error || !res.payload) return console.error('API Error', res.error)
      // console.log('res',res.payload)
      return res.payload
    })
    .catch(err => console.error('Fetch err:', err))
}

const getRelationshipData = async (userId) => {
  return await fetch(`https://kkxqq9hr4i.execute-api.us-east-1.amazonaws.com/findRelationships/${userId}`)
    .then(async resp => {
      const res = await resp.json()
      if (res.error || !res.payload) return console.error('API Error', res.error)
      return JSON.parse(res.payload)
    })
    .catch(err => console.error('Fetch err:', err))
}

const getTransactionData = async (relId) => {
  // return await fetch(`https://kkxqq9hr4i.execute-api.us-east-1.amazonaws.com/getTransactions/${relId}`)
  return await fetch(`https://api.runningtabs.com/getTransactions/${relId}`)
    .then(async resp => {
      const res = await resp.json()
      if (res.error || !res.payload) return console.error('API Error', res.error)
      return JSON.parse(res.payload)
    })
    .catch(err => console.error('Fetch err:', err))
}

const getUserData = async (username) => {
  console.log('here getUserData', username)
  return await fetch(`https://api.runningtabs.com/getUser/${username}`)
  // return await fetch(`https://kkxqq9hr4i.execute-api.us-east-1.amazonaws.com/getUser/${username}`)
    .then(async resp => {
      const res = await resp.json()
      if (res.error || !res.payload) return console.error('API Error', res.error)
      return res.payload
    })
    .catch(err => console.error('Fetch err:', err))
}

const submitTransaction = async (txData) => {
  // return await fetch('https://kkxqq9hr4i.execute-api.us-east-1.amazonaws.com/createTransaction', {
  return await fetch('https://api.runningtabs.com/createTransaction', {
    method: 'post',
    body: JSON.stringify(txData)
  })
    .then(async resp => {
      const res = await resp.json()
      // console.log('res',res)
      if (res.error || !res.payload) return console.error('API Error', res.error)
      // console.log('res',res.payload)
      return res.payload
    })
    .catch(err => console.error('Fetch err:', err))
}

export {
  checkUser,
  getRelationshipData,
  getTransactionData,
  getUserData,
  submitTransaction,
}
