import {
  // BrowserRouter as Router,
  // ,Link
  // Route,
  // Switch,
  useHistory,
  // useParams,
} from 'react-router-dom'
import './UserView.css'

export default function Splash() {
  let history = useHistory()
  const goToUsername = () => {
    const userId = document.getElementById('inputUsername').value
    if (userId) history.push(`/${userId}/friends`)
  }

  return (<>
    If you have an account, <a>Login here</a>
    <div id='tempUsernameEntry'>
      <input id='inputUsername' placeholder='Enter username' />
      <button onClick={goToUsername}>See user</button>
    </div>
  </>)
}
