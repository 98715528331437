import './TransactionsView.css'

export default function TransactionsView({ txs, user }) {

  return (<>
    {txs && txs.map(tx => {
      console.log('tx',tx)
      let borrower = 'N/A', lender = 'N/A'
      if (tx.Borrower === user.UserId) {
        borrower = 'You'
      } else if (typeof tx.Borrower === 'object') {
        borrower = `${tx.Borrower.FirstName} ${tx.Borrower.LastName} (${tx.Borrower.Username})`
      }
      if (tx.Lender === user.UserId) {
        lender = 'You'
      } else if (typeof tx.Lender === 'object') {
        lender = `${tx.Lender.FirstName} ${tx.Lender.LastName} (${tx.Lender.Username})`
      }

      return (
        <div className='txBox' key={tx.txId}>
          Borrower: {borrower}<br/>
          Lender: {lender}<br/>
          Note: {tx.Note}<br/>
          Amount: {tx.Currency === 'USD' ? '$' : ''}{tx.Amount}
        </div>
      )
    })}
  </>)
}
