import { useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom'
import { CircularProgress, Tab, Tabs } from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import ReceiptIcon from '@material-ui/icons/Receipt'
import FriendsView from './views/FriendsView'
import TransactionsView from './views/TransactionsView'
import UserView from './views/UserView'
import {
  getUserData,
} from './helpers/dbUtils'
import '@fontsource/roboto'
import './App.css'

function getTabFromPage(page) {
  if (page === 'friends') return 0
  else if (page === 'transactions') return 1
  else if (page === 'user') return 2
}

export default function App(props) {
  const page = getTabFromPage(props.match.params.page)
  let history = useHistory()
  const getUser = async (username) => {
    setLoadingUser(true)
    console.log('getUser', username)
    const resUserData = await getUserData(username)
    console.log('resUserData',resUserData)
    const {transactions = [], relationships = [], ...user} = resUserData
    setRels(relationships)
    setUser(user)
    // const resTxs = await getTransactionData(1)
    const updatedTransactions = []
    transactions.map(tx => {
      const tempTx = {...tx}
      const rl = relationships.find(rel => rel.RelId === tx.RelId)
      if (rl?.Other) {
        if (tx.Lender === rl.Other.UserId) {
          tempTx.Lender = rl.Other
        } else if (tx.Borrower === rl.Other.UserId) {
          tempTx.Borrower = rl.Other
        }
      }
      updatedTransactions.push(tempTx)
    })
    setTxs(updatedTransactions)
    setLoadingUser(false)
  }

  const { /* page, */ username } = useParams()
  const [ loadingUser, setLoadingUser ] = useState(false)
  const [ rels, setRels ] = useState([])
  const [ tab, setTab] = useState(page)
  const [ txs, setTxs ] = useState([])
  const [ user, setUser ] = useState({})

  useEffect(() => {
    if (username) getUser(username)
  }, [username])

  const changeTab = (e, newTab) => {
    console.log('newTab',newTab)
    setTab(newTab)
    if (newTab === 0) {
      history.push(`/${username}/friends`)
    } else if (newTab === 1) {
      history.push(`/${username}/transactions`)
    } else if (newTab === 2) {
      history.push(`/${username}/user`)
    }
  }

  return (<>
    <header className='App-header'>
      Welcome to Running Tabs
      <Tabs
        value={tab}
        onChange={changeTab}
        indicatorColor='secondary'
        textColor='secondary'
        centered
      >
        <Tab label='Friends' icon={<PeopleIcon />} />
        <Tab label='Transactions' icon={<ReceiptIcon />} />
        <Tab label='User' icon={<PersonIcon />} />
      </Tabs>
    </header>
    <div className='mainApp'>
      {loadingUser ? (
        <CircularProgress color='secondary' />
      ) : (
        <Switch>
          <Redirect exact from='/:username/' to='/:username/friends' />
          <Route path='/:username/friends'>
            <FriendsView rels={rels} user={user} />
          </Route>
          <Route path='/:username/transactions'>
            <TransactionsView txs={txs} user={user} />
          </Route>
          <Route path='/:username/user'>
            <UserView rels={rels} user={user} />
          </Route>
        </Switch>
      )}
    </div>
  </>)
}
