// import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import App from './App'
import Splash from './views/Splash'
// import reportWebVitals from './reportWebVitals'
import './index.css'

ReactDOM.render(
  // <React.StrictMode>
  <Router className='App'>
    <Route path='/:username/:page' component={App} />
    <Route exact path='/' component={Splash} />
  </Router>
  // </React.StrictMode>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
