import { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import NewTransaction from '../components/NewTransaction'
import './FriendsView.css'

export default function FriendsView({rels, user}) {
  const [ addTxShowing, setAddTxShowing ] = useState(false)
  const [ addTxOtherId, setAddTxOtherId ] = useState('')
  const [ addTxOtherUn, setAddTxOtherUn ] = useState('')
  const [ addTxRelId, setAddTxRelId ] = useState('')

  const toggleTransactionForm = (friendUn = '', friendId = null, relId = 0) => {
    setAddTxOtherId(friendId)
    setAddTxOtherUn(friendUn)
    setAddTxRelId(relId)
    setAddTxShowing(!addTxShowing)
  }

  return (
    <div id='friendView'>
      {rels.map(rel => (
        <div className='friendBox' key={rel.id}>
          {`${rel.Other.FirstName} ${rel.Other.LastName} (${rel.Other.Username})`}
          <br/>
          ${rel.Amount}
          <div className='btnAddTx' onClick={() => toggleTransactionForm(rel.Other.Username, rel.Other.UserId, rel.RelId)}>
            <AddIcon />
          </div>
        </div>
      ))}
      <div className='friendBox' id='buttonAddTransaction' onClick={() => toggleTransactionForm()}>
        Add New Transaction
        <div className='btnAddTx'>
          <AddIcon />
        </div>
      </div>

      {addTxShowing && <NewTransaction userId={user.UserId} otherUn={addTxOtherUn}  otherId={addTxOtherId} relId={addTxRelId} />}
    </div>
  )
}
