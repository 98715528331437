import { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { submitTransaction } from '../helpers/dbUtils'

export default function NewTransaction({otherId, otherUn, relId, userId}) {
  const [ amount, setAmount ] = useState(0)
  const [ isLending, setIsLending ] = useState(true)
  const [ note, setNote ] = useState('')
  const [ submittingTx, setSubmittingTx ] = useState(false)

  const submitNewTx = async () => {
    setSubmittingTx(true)
    const resSubmit = await submitTransaction({
      Borrower: isLending ? otherId : userId,
      Lender: isLending ? userId : otherId,
      RelId: relId,
      Amount: amount,
      Currency: 'USD',
      Note: note,
    })
    console.log('resSubmit',resSubmit)
    setSubmittingTx(false)
  }

  return (
    <div className='txBox' id='txBox'>
      --- Debug:<br/>otherId:{otherId} otherUn:{otherUn} userId:{userId} amount:{amount} note:{note}---<br/>
      Transaction with&nbsp;
        {otherId && otherUn ? otherUn : (
          <input id='setOther' placeholder='friend username' onChange={(e, v) => setOther(v)} />
          // <input id='setOther' placeholder='set username' onChange={(e, v) => updateNewUser(v)} />
        )}
      <div id='closeTxBox' onClick={() => toggleTransactionForm()}>

      </div>
      <br/>
      <ToggleButtonGroup
        value={isLending}
        exclusive
        onChange={(e,v) => v !== null && setIsLending(v)}
        aria-label='Who is lending'
      >
        <ToggleButton value={true} aria-label='I am lending'>
          {/* I paid<br/> */}
          Lend
        </ToggleButton>
        <ToggleButton value={false} aria-label='I am lending'>
          {/* <div id='friendId'>{Other}</div> paid<br/> */}
          Borrow
        </ToggleButton>
      </ToggleButtonGroup>
      {/* <button>I paid</button>
      <button><div id='friendId'>{Other}</div> paid</button> */}
      <br/>
      Amount: <input id='setAmount' type='number' value={amount} onChange={e=>setAmount(e.target.value)} />
      <br/>
      Add Note: <input id='addNote' value={note} onChange={e=>setNote(e.target.value)} />
      <br/>
      {submittingTx ? (
        <CircularProgress color='secondary' />
      ) : (
        <button
          id='submitTx'
          onClick={() => submitNewTx()}
          disabled={!(otherId && amount > 0 && note)}
        >Complete</button>
      )}
    </div>
  )
}
